import { ItemConstants as Constants } from '../Types'



const initialState = {
    items: []
};



export function ItemReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                items: action.payload.items,
            };
       
    
        case Constants.FAILED:
            return{
                ...state,
                items: [],
            }

        default:
            return state
    }
}