import React, {useState, useEffect} from "react";
import moment from 'moment'
import { Box, Text, Button, HStack, Heading } from "@chakra-ui/react";

const Calendar = ({title, val, minDate, offset,  onSelect}) => {

    // let startDate = ;
    let endDate = moment().add(20, 'days');

    const [startDate, setStartDate] = useState(minDate ? moment(minDate).add(offset || 1, 'days') :  moment().add(offset || 1, 'days'))

    const [dates, setDates] = useState([]) 
    const [value, setValue] = useState(val)

    useEffect(() => {
        let prevDate = moment(val);
        if(!val || prevDate <= startDate){
            onSelect(moment(startDate).format('YYYY-MM-DD'))
            setValue(moment(startDate).format('YYYY-MM-DD'))
        }        
    }, [startDate])


    useEffect(() => {
        setStartDate(minDate)
        // let prevDate = moment(val);
        // if(!val || prevDate <= startDate){
        //     onSelect(moment(startDate).format('YYYY-MM-DD'))
        //     setValue(moment(startDate).format('YYYY-MM-DD'))
        // }        
    }, [minDate])


    useEffect(() => {        
        let allDates = [];
        for (var m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
            allDates = [...allDates, m.format('YYYY-MM-DD')]
          }        
        setDates(allDates)
    }, [])

    const handleOnSelect = (adate) => {
        onSelect(adate)
        setValue(adate)        
    }

    return(
        <Box py="10px">
            <Box>
                <Heading mt="20px" mb="15px" size="sm">{title || 'Select Date'}</Heading>
            </Box>
            <Box d="flex" pb="10px" overflowX="auto" h="100px" >
                <HStack py="10px">
                    {
                        dates.map((aDate, a) =>
                        <Button                             
                            // variant={value === aDate ? "outline" : "ghost"}  
                            variant="unstyled"
                            h="auto"
                            w="70px"
                            p={2}
                            px="10px"
                            onClick={() => handleOnSelect(aDate)} 
                            // colorScheme={ value === aDate ? "secondary" : "gray"}
                            bg="white"
                            key={a}   
                            mx="5px"
                            display="inline-block"
                            borderWidth={2}
                            borderColor={value === aDate ? 'secondary.500' : "white"}
                            
                            >
                                <Text  fontSize="xs" >{moment(aDate).format('ddd')}</Text>
                                <Text  fontWeight={value === aDate ? 'bold' : 'normal'}  fontSize="lg">{moment(aDate).format('DD')}</Text>
                                <Text   fontSize="xs">
                                    {moment(aDate).format('MMM')}, {moment(aDate).format('YY')}
                                </Text>
                                
                        </Button>
                        
                        )
                    }
                </HStack>
            </Box>
        </Box>
    )
}

export {Calendar}