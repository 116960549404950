import { OrderConstants as Constants } from '../Types'



const initialState = {
    orders: []
};



export function OrderReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                orders: action.payload.orders,
            };

        case Constants.ADD:
            return {
                ...state,               
                orders: [...state.orders, action.payload.order]
            };
       
              
            
        case Constants.FAILED:
            return{
                ...state,
                orders: [],
            }

        default:
            return state
    }
}