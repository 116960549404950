import React from 'react'
import {Box} from '@chakra-ui/react'

export const Wrapper = (props) => {
    return(
        <Box w="100%" maxW="1400px" px="2em" mx="auto" h="auto" {...props}>
            {props.children}
        </Box>
    )
}

