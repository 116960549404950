import { useSelector } from 'react-redux'

const SortItems = (items) => {
    
    const {services} = useSelector(state => state.services)

    let sortedItems = [];
    services.map((service) => {
        return sortedItems.push({
            service: service.title, 
            items: Object.fromEntries(
                        Object.entries(items).filter(
                        ([key, value]) => parseInt(value.service_id) === parseInt(service.id)
                )
              )
        })
    })
    return sortedItems;

}

export {SortItems}