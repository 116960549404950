export const button = {
        baseStyle: {
            backgroundColor: 'brand',
            lineHeight: "auto",
            _focus: { 
                boxShadow: 'none' 
            },
        },        
        defaultProps: {
            colorScheme: 'brand'
        },
        variants:{
            light:{
                bg: 'white',
                color: '#00BFFF',
                // borderWidth: "2px",
                // borderColor: 'gray.300',
                _hover:{
                    bg: "#00BFFF",
                    color: 'white'
                }
            }
        }
}