import { AuthConstants as Constants } from '../Types'
import { authApi as api } from '../../Api'

export const AuthActions = {
    Login,
    Logout,
    Signup,
    SocialLogin,
    AddAddress,
};


function Login(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api.login(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LOGIN, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function Logout() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api.logout()
                .then(
                    payload => {
                        dispatch(success());
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success() { return { type: Constants.LOGOUT } }
    function failure() { return { type: Constants.FAILED } }
    }
}



function Signup(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api.signup(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LOGIN, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function SocialLogin(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api.social_auth(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LOGIN, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}



function AddAddress(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api.add_address(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADDRESS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}