import { ItemConstants as Constants } from '../Types'
import { itemsApi as api } from '../../Api'

export const ItemActions = {
    Get
};


function Get(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}
