import { apiUrl, tokenName, apiMaxTime} from '../config.json'
import {handleResponse, requestTimeout} from '../Helpers'

export const ordersApi = {
    _get,
    _add
};


async function _get() {
    const app_token = localStorage.getItem(tokenName)

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'orders', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _add(data) {
    const app_token = localStorage.getItem(tokenName)
    const requestOptions = {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + app_token
        },
        body: data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'orders', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

