import React from 'react'
import { ChakraProvider } from "@chakra-ui/react"
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { AppStore, persistor } from './Redux'
import { AlertProvider, AuthProvider, CartProvider } from './Services'

import {theme} from './Theme'
import Routes from './Routes'


const App = () => {    
    return (
        <Provider store={AppStore}>
            <PersistGate loading={null} persistor={persistor}>
                <ChakraProvider theme={theme}>    
                    <AlertProvider>
                        <AuthProvider>
                            <CartProvider>                            
                                <Routes />                              
                            </CartProvider>
                        </AuthProvider>                        
                    </AlertProvider>
                </ChakraProvider>
            </PersistGate>
        </Provider>
      )
}

export default App
