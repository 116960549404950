import React from 'react'


const NotFound = (props) => {

    return(
        <p>404 Page Not Found</p>
    )
}


export default NotFound;