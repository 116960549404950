import { extendTheme } from "@chakra-ui/react"
import { colors } from "./colors"
import { button } from "./button"
import { input } from "./input"
import { heading } from "./heading"
export const theme = extendTheme({  
    styles:{
        global:{
            body:{
                color: 'gray.500',
            }
        }
    },
    fonts: {
        heading: "Heebo",
        body: "Heebo",
    },
    colors: colors,
    components:{
        Button: button,
        Input: input,
        Heading: heading,
    }
})