import React, { createContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AuthActions } from '../Redux/Actions';
import { authApi } from '../Api';
const initialState = {
    user: null,   
    loggedIn: false,
    token: null, 
    loading: false,
    phone: null,
};




const AuthContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    login: () => Promise.resolve(), 
    signup: () => Promise.resolve(), 
    social_auth: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    verify: () => Promise.resolve(),
    
});


export const AuthProvider = (props) => {
    const { children } = props;
    const auth = useSelector(state => state.auth);
    const [state, setState] = useState({...auth, ...initialState})
    const dispatch = useDispatch()
   


    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [])

    useEffect(() => {
        setState({
            ...state,
            ...auth
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    const init = () => {      
        setTimeout(function() { loader() }, 1500);
    }


    const login = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(AuthActions.Login(data))
                .then((response) => resolve(response) )
                .catch((error) => reject(error) )
        })
    }

    const signup = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(AuthActions.Signup(data))
                .then((response) => resolve(response) )
                .catch((error) => reject(error) )
        })
    }

    const social_auth = (data) => {
        return new Promise((resolve, reject) => {
            dispatch(AuthActions.SocialLogin(data))
                .then((response) => resolve(response) )
                .catch((error) => reject(error) )
        })
    }


    const logout = () => {
        dispatch(AuthActions.Logout())
    }
   

     const authCheck = () => {
        return new Promise((resolve, reject) => {
            authApi.authCheck()
                .then((res) => {
                    setState({...state, loading: false})
                    resolve()
                })
                .catch((e) => {
                    setState({...state, loading: false})
                    reject()
                })
        })
    }

    const verify = (phone) => {
        return new Promise((resolve, reject) => {
            authApi.verifyPhone(phone)
                .then((response) => {
                    setState({...state, phone: response})
                    resolve(response) 
                })
                .catch((error) => reject(error) )
        })
    }


    /* Check initial authentication here */
    const loader = async () => {
        // await authCheck()
        // setState({...state, loading: false})
    }
    
    console.log("State", state)
    return (
        <AuthContext.Provider
        value={{
            ...state,
            login,
            logout,
            social_auth,
            verify,
            signup
        }}
        >
        {children}
        </AuthContext.Provider>
    );
}

export {AuthContext};

