export const USER_LOGOUT = 'USER_LOGOUT'

export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    UPDATE: 'UPDATE_PROFILE',
    ADDRESS: 'ADD_ADDRESS'
}

export const ServicesConstants = {
    GET: 'GET_SERVICES',
    FAILED: 'SERVICES_FAILED',    

};

export const CategoryConstants = {
    GET: 'GET_CATEGORIES',
    FAILED: 'CATEGORIES_FAILED'    
};

export const ItemConstants = {
    GET: 'GET_ITEMS',
    FAILED: 'ITEMS_FAILED',    
};


export const OrderConstants = {
    GET: 'GET_ORDERS',
    FAILED: 'ORDERS_FAILED',    
    ADD: 'ADD_ORDER',
    REMOVE: 'REMOVE_ORDER',
    UPDATE: 'UPDATED_ORDER',
};