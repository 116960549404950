export const input = {    
        baseStyle:{
            backgroundColor: 'white',
        },
        variants: {
            outline: {
                field:{
                    bg: 'white',
                    _focus: {
                        bg: 'gray.50'
                    }
                }
            },
        },
    }

