import { ServicesConstants as Constants } from '../Types'


const initialState = {
    services: []
};



export function ServiceReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                services: action.payload.services,
            };
       
              
            
        case Constants.FAILED:
            return{
                ...state,
                services: [],
            }

        default:
            return state
    }
}