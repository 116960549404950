import React from 'react'
import {
    FormControl,
    FormLabel,
    Input as ChakraInput,
    FormErrorMessage,
    FormHelperText,
} from "@chakra-ui/react"

export const Input = ({label, helperText, error, ...props}) => {
    return(
        <FormControl isInvalid={error}>            
            {label && <FormLabel>{label}</FormLabel>}            
            <ChakraInput rounded="2px" {...props} />
            { helperText && <FormHelperText>{helperText}</FormHelperText>}
            { error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
    )
}

