import { combineReducers } from 'redux'
import { AuthReducer } from './Auth.reducer'
import { ItemReducer } from './Item.reducer';
import { OrderReducer } from './Order.reducer';
import { ServiceReducer } from './Service.reducer';

export default combineReducers({
    auth: AuthReducer,
    services: ServiceReducer,
    items: ItemReducer,
    orders: OrderReducer
});