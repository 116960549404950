import { AuthConstants as Constants } from '../Types'
import {tokenName} from '../../config.json'


let token = localStorage.getItem(tokenName);
const initialState = {
    user: {addresses:[]},
    loggedIn: false,
    token: token,
};



export function AuthReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.LOGIN:
            return {
                ...state,
                loggedIn: true,
                token: action.payload.token,
                user: action.payload.user,
            };

        case Constants.ADDRESS:
                return { 
                    ...state, 
                    user: { ...state?.user, 
                        addresses: [
                            ...state.user?.addresses, action.payload.address 
                            ] 
                        } 
                    };

       
        case Constants.LOGOUT:
            return{
                ...state,
                loggedIn: false,
                token: null,
                user: {},
            };
       

      
            
        case Constants.FAILED:
            return{
                ...state,
                loggedIn: false,
                token: null,
                user: {},
            }

        default:
            return state
    }
}