import React, {useState, useEffect} from 'react'
import { Box, Text, Button, HStack } from "@chakra-ui/react"
import { Icon } from '.'

const QtySelector = ({value = 0, onChange}) => {

    
    const [val, setVal] = useState(value)
    
    const handlePlus = () => {
        if(val < 30){            
            setVal(val + 1)
            onChange(val + 1)
        }
    }

    const handleMinus = () => {
        if(val > 0){
            setVal(val - 1)
            onChange(val - 1)
        }
    }

    useEffect(() => {
      setVal(value)
    }, [value])
    console.log("Value", value)
    console.log("Val", val)

    return(
        <HStack my="2" spacing="1" alignItems="center">
            <Button
                variant="ghost"
                size="sm"
                onClick={handleMinus}
                rounded="full"
                >
                <Icon fontSize="24px" name="md-remove-circle-outline" color="brand.500" />
            </Button>
            <Box w="28px">
                <Text textAlign="center">{val}</Text>
            </Box>
             <Button
                variant="ghost"
                size="sm"
                onClick={handlePlus}
                rounded="full"
                >
                <Icon fontSize="24px" name="md-add-circle-outline" color="brand.500" />
            </Button>
        </HStack>
    )
}

export {QtySelector}