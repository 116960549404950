import React,{useState, useEffect} from 'react'
import {Input, Box, Text, HStack, FormControl, InputLeftAddon, InputGroup} from '@chakra-ui/react'
import { Modal } from '.'
import Flag from 'react-flagkit'
import { countries } from '../Helpers/countries'

const PhoneInput = ({value, readOnly=false, defaultCountry, onChange=null, ...props}) => {

    const [visible, setVisible] = useState(false)
    const [innerValue, setInnerValue] = useState(value)
    const [filtered, setFiltered] = useState(countries)
    const [selected, setSelected] = useState({sortname: defaultCountry || 'PK', phonecode: defaultCountry || '92'})

    useEffect(() => {
        let default_vals = countries.find((item) => item.sortname.toUpperCase() === (selected.sortname.toUpperCase()) )
        if(default_vals)
            setSelected(default_vals)
        // eslint-disable-next-line
    }, [])


    function handleOnChange (val) {        
        let cleen_value = val.replace(/[^0-9]/g, '')
        setInnerValue(cleen_value)
        onChange({
                formated: selected.phonecode+""+cleen_value,
                values: {code: selected.phonecode, country: selected.sortname, number: cleen_value}
            })
        
    }

    function handleFilter (vals) {
        if(vals){
            let val = vals.replace('+', '')
            let results = countries.filter((item) => {
                if(item.name.toUpperCase().includes(val.toUpperCase()) || item.sortname.toUpperCase() === val.toUpperCase() || item.phonecode.includes(val))
                    return item;
                else
                    return []
            })
            setFiltered(results)
        }
        else{
            setFiltered(countries)
        }
    }

    function handleClose(){
        setVisible(false)
        setFiltered(countries)        
    }

    function handleSelect(val){   
        setSelected(val)     
        handleClose()
    }

    return(
        <>
        <FormControl isInvalid={false} mb={3}>                                    
            <InputGroup rounded="2px">
                <InputLeftAddon                     
                    children={<HStack  alignItems="center" {...(!readOnly && {onClick: () => setVisible(true) }) }><Flag country={selected?.sortname}  /> <Text>+{selected.phonecode}</Text></HStack>}
                />                                
                <Input rounded="3" value={innerValue} disabled={readOnly} {...props} onChange={(e) => handleOnChange(e.target.value)} />
            </InputGroup>
        </FormControl>  
        <Modal
            open={visible}
            heading="Select Country"
            onClose={() => setVisible(false)}
            scrollBehavior="inside"
            >                   
            <Box flex={1}>
                <Box mx="5" mb="5">
                    <Input 
                        defaultValue=""
                        bg="gray.100"                        
                        px="4"
                        onChange={(e) => handleFilter(e.target.value)}
                        placeholder="Filter Country"
                    />                    
                </Box>
                <Box h="300px" overflowY="auto"  px="3">
                {
                    filtered.map((country, c) =>
                        <Box _active={{ bg: 'coolGray.100' }} w="100%" key={c}   onClick={() => handleSelect(country)}>
                            <HStack px={3} py={3} justifyContent="space-between">
                                <Flag country={country.sortname} size={24} type="flat" />
                                <Text ml={3} flexGrow={1}>{country.name}</Text>
                                <Text ml={3} fontSize="sm">+{country.phonecode}</Text>
                            </HStack>
                        </Box>
                    )
                }
                </Box>
                
            </Box>
                             
        </Modal>
        </>
        
    )
}

export {PhoneInput}