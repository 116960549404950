import { apiUrl, apiMaxTime} from '../config.json'
import {handleResponse, requestTimeout} from '../Helpers'

export const servicesApi = {
    _get
};

async function _get() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'services', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

