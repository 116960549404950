import React from 'react'
import {
        Modal as ChakraModal,
        ModalOverlay,
        ModalContent,
        ModalHeader,
        ModalFooter,
        ModalBody,
        ModalCloseButton,
  } from "@chakra-ui/react"

export const Modal = ({open, onClose, heading, footer, children, ...props }) => {
    return(
        <ChakraModal
            isOpen={open}
            placement="right"
            onClose={onClose}
            {...props}            
            >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>{heading}</ModalHeader>
                <ModalBody p="0">
                    {children}
                </ModalBody>
                {
                    footer &&                 
                    <ModalFooter>
                        {footer}
                    </ModalFooter>
                }
            </ModalContent>
      </ChakraModal>
    )
}