import { AppStore } from "../Redux";
import { AuthActions } from "../Redux/Actions";

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


// export const greetingMessage = () => {
//     if(moment().hour() < 12){
//         return 'Good Morning '
//     }
//     else if(moment().hour() < 18){
//         return 'Good Afternoon '
//     }
//     else if(moment().hour() < 24){
//         return 'Good Evening '
//     }
//     else{
//         return 'Welcome '
//     }
// }

export const getHSLColor = (str, s=50, l=60) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    var h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const getInitials = (name) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...name.matchAll(rgx)] || [];
    return initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
}

export const handleResponse = (response) => {
    return response.text()
        .then(async (my_data) => {     
            // console.log("DATA", my_data)
            if(response.status === 500)
            {
                return Promise.reject({error: 'Error 500: Internal Server Error.'})
            }
            if(response.status === 404)
            {
                return Promise.reject({error: 'Error 404: Requested uri does not exist.'})
            }

            if(response.status === 401)
            {            
                AppStore.dispatch(AuthActions.Logout())
                return Promise.reject()
                
            }

            if(response.status === 400)
            {                
                const data = my_data && JSON.parse(my_data);
                return Promise.reject(data)
            }

            const data = my_data && JSON.parse(my_data);
            if (!response.ok) {
                // throw Error(response.statusText);
                const error = (data && data.error) || response.statusText;
                return Promise.reject(error);
            }

            return data
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

export const requestTimeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
        reject({error:'Server Request Timeout'})
        }, ms)      
        promise
        .then(value => {
            clearTimeout(timer)
            resolve(value)
        })
        .catch(reason => {
            clearTimeout(timer)            
            reject(reason)
        })
    })
}



export * from './timeslots'