import React, {useState, useEffect} from "react";
import moment from 'moment'
import { Box, Button, HStack, Heading } from "@chakra-ui/react";
import {timeSlots} from '../Helpers'


const TimePicker = ({title, val, onSelect}) => {

    
    const d = moment().format('YYYY-MM-DD') + "";
    const slots = timeSlots(60, "0900", "2200")
    const [value, setValue] = useState(val)
    
    useEffect(() => {
        if(!val){
            handleOnSelect("09:00") 
            setValue("09:00")
        }
                   
    }, [val])

    const handleOnSelect = (adate) => {
        setValue(adate)
        onSelect(adate)
    }

    return(
        <Box py="10px">
            <Box>
                <Heading mt="10px" mb={0} size="sm">{title || 'Select Time'}</Heading>
            </Box>
            <Box d="flex" pb="10px" overflowX="auto" h="100px" >
                <HStack py="10px">
                    {
                        slots.map((slot, t) =>
                        <Button                             
                            variant={value === slot ? "outline" : "ghost"}
                            onClick={() => handleOnSelect(slot)} 
                            colorScheme={ value === slot ? "gray" : "gray"}
                            bg="white"
                            key={t}   
                            mx="5px"
                            p={2}
                            borderWidth={2}
                            borderColor={value === slot ? 'secondary.500' : 'white'}
                            >
                            {moment(d+" "+slot).format('hh:mma')}
                        </Button>
                        )
                    }
                </HStack>
            </Box>
        </Box>
    )
}

export {TimePicker}