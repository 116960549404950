import React, { createContext, useEffect, useState } from 'react';

const initialState = {
    cart: [],   
    total: {price: 0, items: 0},
    order: {
        pickup_date: null,
        pickup_time: null,
        delivery_date: null,
        delivery_time: null,
        pickup_address: null,
        delivery_address: null,
        payment_method: 'COD'
    }    
};


async function getCartItems() {
    try {
        let value = localStorage.getItem('@cartItems');
        if(value){
            value = JSON.parse(value);
            return Promise.resolve(value)
        }
        else
            throw new Error("Whoops!");        
    }
    catch (error) {
        return Promise.resolve({});
    }
}

async function setCartItems(value) {
    try {
        let jsonData = value ? JSON.stringify(value) : JSON.stringify([])
        localStorage.setItem('@cartItems', jsonData);
            return Promise.resolve(value)        

    }
    catch (error) {
        return Promise.reject('Error adding Item');
    }
}


const CartContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    onChange: () => Promise.resolve(), 
    clear: () => Promise.resolve(),
    changeOrder: () => Promise.resolve()    
});


export const CartProvider = (props) => {
    const { children } = props;
    const [state, setState] = useState({...initialState})
    
    const init = () => {
        return new Promise((resolve, reject) => {
            getCartItems()
                .then((items) => {
                    if(items){
                        let calcTotal = 0;
                        let calcItems  = 0;
                        Object.keys(items).map((key, index) => {
                                // console.log("Item", cartItems[key])    
                                calcTotal+= parseFloat(items[key].price * items[key].qty)
                                calcItems+= parseInt(items[key].qty)
                                return calcTotal
                            }
                        )
                        let data = {...state, cart: items, total: {price: calcTotal.toFixed(0), items: calcItems}}
                        setState(data)
                    }
                    resolve(items)
                })
                .catch((err) => {
                    reject(err)
                })
        })                    
    }

    const onChange = (item, key, value, index) => {
        return new Promise((resolve, reject) => {
            getCartItems()
                .then((items) => {
                    let updateItems = {...items, 
                        [index]:{
                            ...item, 
                        [key]: value
                        }
                    }
                    setCartItems(updateItems)
                    setState({...state, cart: updateItems})
                    resolve(updateItems)
                })
                .catch((error => {
                    reject(error)
                }))
                
        })
    }

    const changeOrder = (key, value) => {        
        // setState(prevState => {                            
        //     prevState.order[key]= value                
        //     return prevState;
        // });
        setState({
            ...state,
            order:{
                ...state.order,
                [key]: value
            }
        })
    }

    const clear = () => {
        return new Promise((resolve, reject) => {
            setState(initialState)
            resolve(setCartItems([]))
        })
    }

    useEffect(() => {
        if(state?.cart){
            let calcTotal = 0;
            let calcItems  = 0;
            Object.keys(state.cart).map((key, index) => {
                    calcTotal+= parseFloat(state?.cart?.[key].price * state?.cart?.[key].qty)
                    calcItems+= parseInt(state?.cart?.[key].qty)
                    return calcTotal;
                }
            )
            setState({
                ...state,
                total:{
                    price: calcTotal.toFixed(0),
                    items: calcItems
                }
            })            
        }
        // eslint-disable-next-line
    }, [state?.cart])

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, [])

    return (
        <CartContext.Provider
        value={{
            ...state,
            onChange,
            clear,
            changeOrder,
            init
        }}
        >
        {children}
        </CartContext.Provider>
    );
}

export {CartContext};

