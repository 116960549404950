import React, {Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {Splash} from '../Kit'
import {Fade} from '@chakra-ui/react'
import { useAuth } from '../Hooks'
// import { isEmpty } from '../Helpers'
import NotFound from '../Pages/Errors/404'
// const Login = lazy(() => import('./../Pages/Auth'))
const Home = lazy(() => import('./../Pages/Home'))
const Order = lazy(() => import('./../Pages/Order'))
const About = lazy(() => import('./../Pages/About'))
const How = lazy(() => import('./../Pages/How'))
const Contact = lazy(() => import('./../Pages/Contact'))
const Terms = lazy(() => import('./../Pages/Terms'))
const Account = lazy(()=> import('./../Pages/Account'))


const VerifyAuth = lazy(() => import('./../Pages/Auth/VerifyAuth') )

const PlaceOrder = lazy(() => import('./../Pages/Account/PlaceOrder') )

const  Routes = () => {    
    
    const auth = useAuth();

    if(auth.loading)
    {
        return(
            <Fade in={true}>
                <Splash />
            </Fade>
        )
    }

    // if(isEmpty(auth.user) || !auth.token) 
    // {
    //     return (
    //         <Fade in={true}>
    //             <Suspense fallback={<Splash />}>
    //                 <Router>
    //                     <Switch>
    //                         <Route exact path="/" component={Login} />
    //                         <Route path="*"  component={NotFound} /> 
    //                     </Switch>
    //                 </Router>
    //             </Suspense>
    //         </Fade>
    //     )
    // }

    return (        
        <Fade in={true}>
            <Suspense fallback={<Splash />}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Home} />    
                        <Route exact path="/order" component={Order} />    
                        <Route exact path="/about" component={About} />  
                        <Route exact path="/terms" component={Terms} />    

                        <Route exact path="/how-it-works" component={How} />    
                        <Route exact path="/contact" component={Contact} />    
                        <Route exact path="/auth" component={VerifyAuth} />
                        <Route exact path="/account" component={Account} />   


                        <Route exact path="/place-order" component={PlaceOrder} />    
                        
                        <Route path="*"  component={NotFound} />                     
                    </Switch>
                </Router>
            </Suspense>
        </Fade>        
    )
}

export default Routes;